import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";

export default {
	props: {
		variation: {
			required: false
		},

		sizePrices: {
			required: true
		},

		featurePrices: {
			required: true
		},

		cartId: {
			required: false
		},

		isAdmin: {
			required: false,
			default: false
		},
	},

	data() {
		return {
			windowSide: 'front_side'
		}
	},

	mounted() {
		this.$nextTick(function () {
			this.iframeHeightNotify()
			this.$nextTick(function () {
				this.iframeHeightNotify()
			})
		})
	},

	methods: {
		showInventoryModal: function () {
			this.$refs.inventoryModal.showModal()
		},

		showCustomProductModal: function () {
			this.$refs.customProductModal.showModal()
		},

		init() {
			this.three.camera_z_value = 14
			const _this = this
			const width = window.innerWidth;
			this.three.renderer = new THREE.WebGLRenderer({
				antialias: true,
				alpha: true,
				canvas: document.getElementById("window"),
			});
			this.three.scene = new THREE.Scene();

			if (width <= 768) {
				_this.three.renderer.setSize(396, 496)
				_this.three.camera = new THREE.PerspectiveCamera(75, 396 / 496);
				_this.three.scene.add(_this.three.camera);
			} else if (width > 768) {
				_this.three.renderer.setSize(_this.$refs.canvasWrapper.offsetWidth, _this.$refs.formsWrapper.offsetHeight)
				_this.three.camera = new THREE.PerspectiveCamera(78, _this.$refs.canvasWrapper.offsetWidth / _this.$refs.canvasWrapper.offsetHeight);
				_this.three.scene.add(_this.three.camera);
			}

			this.three.renderer.setPixelRatio(window.devicePixelRatio);
			this.three.renderer.shadowMap.enabled = true;
			this.three.camera.position.set(0, 0, this.three.camera_z_value);
			this.three.ambientLight = new THREE.AmbientLight(0xffffff);
			this.three.scene.add(this.three.ambientLight);
			this.three.directionalLight = new THREE.PointLight(0xffffff, 0.5);
			this.three.directionalLight.position.x = 3.75;
			this.three.directionalLight.position.y = 4;
			this.three.directionalLight.position.z = 5;
			this.three.scene.add(this.three.directionalLight);
			window.addEventListener('resize', function () {
				const width = window.innerWidth;
				if (width <= 768) {
					_this.three.scene.remove(_this.three.camera)
					_this.three.renderer.setSize(396, 496)
					_this.three.camera = new THREE.PerspectiveCamera(75, 396 / 496);
					_this.three.camera.position.set(0, 0, _this.three.camera_z_value);
					_this.three.scene.add(_this.three.camera);
				} else if (width > 768) {
					_this.three.scene.remove(_this.three.camera)
					_this.three.renderer.setSize(_this.$refs.canvasWrapper.offsetWidth, _this.$refs.formsWrapper.offsetHeight)
					_this.three.camera = new THREE.PerspectiveCamera(75, _this.$refs.canvasWrapper.offsetWidth / _this.$refs.canvasWrapper.offsetHeight);
					_this.three.camera.position.set(0, 0, _this.three.camera_z_value);
					_this.three.scene.add(_this.three.camera);
				}
			});
			this.createModel()
			this.animate()
		},

		changeModelSize: function (type) {
			if (!this.isValues()) return;
			const _this = this;

			const is_opened = this.window_opened.v || this.window_opened.h;

			let models = this.static_models;

			const range = {
				min: 190,
				max: 240,
			}

			if (window.innerWidth <= 768 && this.changeWidth >= 130) {
				this.three.camera_z_value = 20
				this.three.camera.position.z = this.three.camera_z_value
			} else {
				this.three.camera_z_value = 14
				this.three.camera.position.z = this.three.camera_z_value
			}

			if (!is_opened) {
				this.three.inside_group = new THREE.Group()
				this.three.outside_group = new THREE.Group()

				this.three.inside_group.name = 'inside_group'
				this.three.outside_group.name = 'outside_group'
				_this.three.scene.remove(_this.three.scene.getObjectByName('mesh_0'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('mesh_1'));

				this.three.pivot_global = new THREE.Group()
				this.three.pivot_global.name = 'global_pivot'

				this.three.pivot_global.rotation.y = THREE.Math.degToRad(40)

				this.three.pivot_global.position.set(0, 0, 0);

				_this.three.scene.remove(_this.three.scene.getObjectByName('global_pivot'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('inside_group'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('outside_group'));

			}

			let width = Math.min(Math.max(this.changeWidth, this.size_values.width.min), this.size_values.width.max) / 2
			let height = Math.min(Math.max(this.changeHeight, this.size_values.height.min), this.size_values.height.max) / 2
			let s = 1.05
			let z = ((this.changeWidth - this.size_values.width.min) * 0.05) + s * 3

			let hr_s = this.window_values.horizontal_grid.split(".")
			let vr_s = this.window_values.vertical_grid.split(".")

			let hr = hr_s[1] === "none" ? 0 : hr_s[1] === "one" ? 1 : hr_s[1] === "two" ? 2 : 3
			let vr = vr_s[1] === "none" ? 0 : vr_s[1] === "one" ? 1 : vr_s[1] === "two" ? 2 : 3

			const brystning = this.window_values.brystning
			const percent =
				brystning.split(".")[1] === "none" ? 0 :
					brystning.split(".")[1] === "half" ? 0.5 :
						brystning.split(".")[1] === "whole" ? 1
							: 0

			const centerEdgeWidth = (percent !== 0 && percent !== 1) ? 2 : 0;
			const upDownEdgeWidth = 2;
			const coverHeight = (Number(height + 18) - centerEdgeWidth - 2 * upDownEdgeWidth) * (percent)
			const glassHeight = (Number(height + 18) - centerEdgeWidth - 2 * upDownEdgeWidth) * (1 - percent)
			let cof;
			if (is_opened || true) {
				for (let f = 0; f < this.grids_array.length; f++) {

					let k = this.grids_array[f].name

					this.three.inside_group.remove(this.three.scene.getObjectByName(k));
				}

				if (hr !== 0 || vr !== 0) {

					this.grids_array = []

					let a = (width + 18) - (upDownEdgeWidth * 2)
					let b = glassHeight

					for (let j = 1; j <= hr; j++) {
						const vr_g_1 = {
							name: 'grid_horizontal_inside' + j,
							z: 0.16,
							x: j,
							b: 2.5,
							cof: b / (hr + 1)
						}
						const vr_g_2 = {
							name: 'grid_horizontal_outside' + j,
							z: 0.04,
							x: j,
							b: 2.5,
							cof: b / (hr + 1)
						}

						this.grids_array.push(vr_g_1)
						this.grids_array.push(vr_g_2)
					}

					for (let g = 1; g <= vr; g++) {
						let hr_g_1 = {
							name: 'grid_vertical_inside' + g,
							z: 0.16,
							x: g,
							b: 2.5,
							cof: a / (vr + 1)
						}
						const hr_g_2 = {
							name: 'grid_vertical_outside' + g,
							z: 0.04,
							x: g,
							b: 2.5,
							cof: a / (vr + 1)
						}
						this.grids_array.push(hr_g_1)
						this.grids_array.push(hr_g_2)
					}

					for (let rn = 0; rn < this.grids_array.length; rn++) {
						let k = this.grids_array[rn].name

						this.three[k] = this.getMesh(k, {
								width: Number(width + 18),
								height: Number(height + 18),
							},
							this.grids_array[rn].cof * this.grids_array[rn].x + this.grids_array[rn].b
						)

						this.three[k].scale.set(0.10, 0.10, 0.10)
						this.three[k].position.z = this.grids_array[rn].z
						this.three.inside_group.add(this.three[k])

						if (k.includes('grid_horizontal_inside') || k.includes('grid_vertical_inside')) {

							this.three[k].material.color.setHex(Number(this.getHex(this.window_values.active_colors.inside)))
						}
						if (k.includes('grid_horizontal_outside') || k.includes('grid_vertical_outside')) {
							this.three[k].material.color.setHex(Number(this.getHex(this.window_values.active_colors.outside)))
						}
					}
				}
			}

			if (!is_opened) {
				for (let i = 0; i < models.length; i++) {

					let k = models[i].name

					this.three.scene.remove(this.three.scene.getObjectByName(k));

					this.three[k] = this.getMesh(k, {
						width: Number(width + 18),
						height: Number(height + 18),
					},)

					this.three[k].scale.set(0.10, 0.10, 0.10)


					this.three[k].position.z = models[i].z

					if (k === 'frame_inside') {
						this.three.inside_group.add(this.three[k])
						this.three[k].position.x = -0.15
					} else if (k === 'frame_inside_front') {
						this.three.inside_group.add(this.three[k])
						this.three[k].position.x = -0.15
					} else if (k === 'frame_outside_colored') this.three.outside_group.add(this.three[k])
					else if (k === 'frame_inside_colored') this.three.outside_group.add(this.three[k])
					else if (k === 'glass') this.three.outside_group.add(this.three[k])
				}

			}

			if (this.changeHeight >= range.min && this.changeHeight <= range.max) {
				let k = this.changeHeight / -30
				this.three.handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.three[model.name].position.y = k
				}
				for (let model of this.grids_array) {
					this.three[model.name].position.y = k
				}
			} else if (this.changeHeight < range.min) {
				let k = this.changeHeight / -24

				this.three.handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.three[model.name].position.y = k
				}
				for (let model of this.grids_array) {
					this.three[model.name].position.y = k
				}
			}

			this.three.pivot_global.add(this.three.inside_group);
			this.three.pivot_global.add(this.three.outside_group);
			this.three.scene.add(this.three.pivot_global)

			for (let item of this.three.inside_group.children) {
				if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
					item.position.x = -this.changeWidth / 33 * 2
				}
			}
			_this.handleSide()

		},

		changeWindowSide: function(key) {
			this.windowSide = key
			if (key === 'front_side') {
				const tween = new TWEEN.Tween(this.three.pivot_global.rotation)
					.to({y: THREE.Math.degToRad(40)}, 1500)
					.easing(TWEEN.Easing.Quadratic.Out)
					.start()
			} else if (key === 'back_side') {
				const tween = new TWEEN.Tween(this.three.pivot_global.rotation)
					.to({y: THREE.Math.degToRad(180 + 40)}, 2000)
					.easing(TWEEN.Easing.Quadratic.Out)
					.start()
			}
		},

		resizeModel() {
			this.three.renderer.setSize(window.innerWidth, window.innerHeight);
			this.three.camera.aspect = window.innerWidth / window.innerHeight;
			this.three.camera.updateProjectionMatrix();
		},

		getFrameColorPrice: function() {
			let outside_price = this.color_price.outside
			let inside_price = this.color_price.inside
			let bothsides_price = this.color_price.bothsides
			let price = this.getCorePrice()

			let total;

			let textTransInside = this.window_values.active_colors.inside.text;
			if (textTransInside === 'hvit') {
				textTransInside = 'white';
			} else if (textTransInside === 'Grå') {
				textTransInside = 'gray';
			} else if (textTransInside === 'Mørk brun') {
				textTransInside = 'brown';
			} else if (textTransInside === 'Svart') {
				textTransInside = 'black'
			}

			let textTransOutside = this.window_values.active_colors.outside.text;
			if (textTransOutside === 'hvit') {
				textTransOutside = 'white';
			} else if (textTransOutside === 'Grå') {
				textTransOutside = 'gray';
			} else if (textTransOutside === 'Mørk brun') {
				textTransOutside = 'brown';
			} else if (textTransOutside === 'Svart') {
				textTransOutside = 'black'
			}

			if (this.isEqColors()) {
				total = price * (bothsides_price[textTransInside] / 100)
			} else if (!this.isEqColors()) {
				total = price * inside_price[textTransInside] / 100
				total += price * outside_price[textTransOutside] / 100
			}

			return Number(total)
		},

		getDegOfIsolation: function() {
			let res = this.window_values.active_isolation

			let price = this.getCorePrice()

			if (res.add_c) {
				price += (res.add_c * this.getWHPrice())
			}

			return Number(price)
		},

		getGridsPrice: function() {
			let table = this.grids.horizontal.table

			let h = this.window_values.horizontal_grid
			let v = this.window_values.vertical_grid

			let res = table[h][v]

			if (this.window_values.grid_color.text === this.colors[0].text) {
				return res * this.grids.price_add.default
			} else {
				return res * this.grids.price_add.not_default
			}
		},

		getHandlePrice() {
			let res = this.window_values.active_handle_colors.price
			return Number(res)
		},
		getChildSafe() {
			let res = this.window_values.childsafe.price
			return Number(res)
		},
		getNotspor() {
			let res = this.window_values.notspor.price
			return Number(res)
		},
		getTypeOfGlass() {
			let res_1 = this.window_values.active_type_of_glass.first.price
			let res_2 = this.window_values.active_type_of_glass.second.price

			Number(res_1)
			Number(res_2)

			return this.getWHPrice() * res_1 + (this.getWHPrice() * Number(res_2))
		},

		isEqColors() {
			return this.window_values.active_colors.inside === this.window_values.active_colors.outside
		},
		isValues() {
			if (this.changeHeight >= this.size_values.height.min && this.changeHeight <= this.size_values.height.max) {
				return this.changeWidth >= this.size_values.width.min && this.changeWidth <= this.size_values.width.max;
			} else {
				return false
			}
		},
		getCorePrice() {
			let c = this.window_values.width
			let w = Math.ceil(c / 10)
			let h = (Math.ceil(this.window_values.height / 10) * 10) + ''
			w = w * 10
			h = h.slice(0, -1);
			let price = 0;
			return Number(price)
		},
		getWHPrice() {
			let sqm = (this.window_values.width / 100) * (this.window_values.height / 100)
			return Number(sqm)
		},

		animate: function () {
			requestAnimationFrame(this.animate);
			this.render();
			TWEEN.update()
		},

		render: function () {
			this.three.renderer.render(this.three.scene, this.three.camera);
		},


		iframeHeightNotify() {
			const msg = {
				height: this.$refs.container.scrollHeight
			};
			window.parent.postMessage(msg, '*')
		},

		getFeatureList: function (name) {
			return this.featurePrices.filter(feature => feature.code.includes(name))
		},

		getMinWidth: function (object) {
			return Math.min(...Object.keys(object).map(Number))
		},

		getMaxWidth: function (object) {
			return Math.max(...Object.keys(object).map(Number))
		},

		getMinHeight: function (object) {
			let sizes = object[Math.min(...Object.keys(object).map(Number))];
			return Math.min(...Object.keys(sizes).map(Number))
		},

		getMaxHeight: function (object) {
			let sizes = object[Math.min(...Object.keys(object).map(Number))];
			return Math.max(...Object.keys(sizes).map(Number))
		},

		getHex: function (text) {
			switch (text) {
				case "frameinside.white":
				case "frameoutside.white":
				case "gridcolor.white":
				case "handlecolor.white":
					return 0xa6a6a6;
				case "frameinside.grey":
				case "frameoutside.grey":
				case "gridcolor.grey":
				case "handlecolor.grey":
					return 0x666666;
				case "frameinside.brown":
				case "frameoutside.brown":
				case "gridcolor.brown":
				case "handlecolor.brown":
					return 0x33230b;
				case "frameinside.black":
				case "frameoutside.black":
				case "gridcolor.black":
				case "handlecolor.black":
					return 0x1c1c1a;
			}
			return 0xff332233;
		},

		f_rect(ctx, x, y, width, height, radius) {
			ctx.moveTo(x, y);
			ctx.lineTo(x + width, y);
			ctx.lineTo(x + width, y + height);
			ctx.lineTo(x, y + height);
		},

		f_rect_reverse(ctx, x, y, width, height, radius) {
			ctx.moveTo(x, y + radius);
			ctx.lineTo(x, y + radius);
			ctx.quadraticCurveTo(x, y, x + radius, y);
			ctx.lineTo(x + width - radius, y);
			ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
			ctx.lineTo(x + width, y + height - radius);
			ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
			ctx.lineTo(x + radius, y + height);
			ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
		},
	}
}
