require('./bootstrap');

import Vue from 'vue'
window.Vue = Vue
import Snotify, { SnotifyPosition } from 'vue-snotify'
const options = {
	toast: {
		position: SnotifyPosition.rightTop,
		timeout: 5000
	}
}

Vue.use(Snotify, options)

Vue.component('outer-door', require('./Pages/OuterDoor.vue').default);
Vue.component('balcony-door', require('./Pages/BalconyDoor.vue').default);
Vue.component('window-fixed', require('./Pages/WindowFixed.vue').default);
Vue.component('window-open', require('./Pages/WindowOpen.vue').default);

// modals
Vue.component('add-inventory-modal', require('./Pages/AddInventoryModal.vue').default)
Vue.component('create-custom-product-modal', require('./Pages/CreateCustomProduct.vue').default)

// components
Vue.component('quantity-input', require('./Components/QuantityInoutComponent.vue').default)

let app = document.getElementById('window-app');

if (app) {
	new Vue({
		el: app,
	});
}

