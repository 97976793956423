<template>
	<div class="modal fade" id="inventoryModal" tabindex="-1" role="dialog" aria-labelledby="inventoryModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div style="display:flex;justify-content: space-between">
						<h5 class="modal-title">Add Inventory</h5>
						<button type="button" class="close" :disabled="loading" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<div class="modal-body">
					<form method="POST" @submit.prevent="addInventory()">
						<div class="row">
							<div class="col-md-6">
								<label for="quantity">Quantity</label>
								<input type="number" min="1" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 1" class="form-control" v-model="inventory.quantity" name="quantity" id="quantity" required>
							</div>
							<div class="col-md-6">
								<label for="name">Navn</label>
								<input type="text" class="form-control" v-model="inventory.name" name="name" id="name" required>
							</div>
							<div class="col-md-12">
								<label for="description">Description</label>
								<textarea class="form-control" rows="12" v-model="inventory.description" name="description" id="description" required></textarea>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="text-right">
									<button type="submit" :disabled="loading" class="btn btn-primary">Add Inventory</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WindowValuesMixin from "../Mixins/WindowValuesMixin";

export default {
	mixins: [WindowValuesMixin],

	props: {
		window_values: {
			type: Object,
			required: true
		},

		type: {
			type: String,
			required: true
		}
	},

	name: 'AddInventoryModal',
	data() {
		return {
			inventory: {
				quantity: 0,
				name: '',
				description: ''
			},
			loading: false
		}
	},
	methods: {
		addInventory: function() {
			this.loading = true
			let data
			if (this.type === 'window-fixed') {
				data = this.getWindowFixedValues()
			} else if (this.type === 'window-open') {
				data = this.getWindowOpenValues()
			} else if (this.type === 'balcony-door') {
				data = this.getBalconyDoorValues()
			} else if (this.type === 'outer-door') {
				data = this.getOuterDoorValues()
			}

			data.quantity = this.inventory.quantity
			data.name = this.inventory.name
			data.type = this.type
			data.description = this.inventory.description

			axios.post('/admin/inventory', data)
				.then(response => {
					this.loading = false
					this.inventory.quantity = 0
					this.inventory.name = ''
					this.inventory.description = ''
					$('#inventoryModal').modal('hide')
					this.$snotify.confirm(response.data.message, 'Its Done!', {
						timeout: 5000,
						showProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						buttons: [
							{
								text: 'Close',
								action: (toast) => {
									this.$snotify.remove(toast.id)
								},
								bold: false,
							},
							{
								text: 'View Inventory?',
								action: () =>
									(window.location = response.data.route),
								bold: true,
							},
						],
					})
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},

		showModal: function () {
			this.inventory.description = this.getWindowDescription();
			this.inventory.quantity = this.window_values.quantity;
			$('#inventoryModal').modal('show');
		},

		getWindowDescription: function () {
			let detail
			if (this.type === 'window-fixed') {
				detail = "- Width: "+ this.window_values.width;
				detail = detail + "\n- Height: "+this.window_values.height;
				detail = detail + "\n- Active isolation: "+this.window_values.active_isolation;
				detail = detail + "\n- First Glass: "+this.window_values.active_type_of_glass.first;
				detail = detail + "\n- Second Glass: "+this.window_values.active_type_of_glass.second;
				detail = detail + "\n- Horizontal grid: "+this.window_values.horizontal_grid;
				detail = detail + "\n- Vertical grid: "+this.window_values.vertical_grid;
				detail = detail + "\n- Inside Color: "+this.window_values.active_colors.inside;
				detail = detail + "\n- Outside Color: "+this.window_values.active_colors.outside;
			} else if (this.type === 'window-open') {
				detail = "- Width: "+ this.window_values.width;
				detail = detail + "\n- Height: "+ this.window_values.height;
				detail = detail + "\n- Active isolation: "+ this.window_values.active_isolation;
				detail = detail + "\n- First Glass: "+ this.window_values.active_type_of_glass.first;
				detail = detail + "\n- Second Glass: "+ this.window_values.active_type_of_glass.second;
				detail = detail + "\n- Horizontal grid: "+ this.window_values.horizontal_grid;
				detail = detail + "\n- Vertical grid: "+ this.window_values.vertical_grid;
				detail = detail + "\n- Inside Color: "+ this.window_values.active_colors.inside;
				detail = detail + "\n- Outside Color: "+ this.window_values.active_colors.outside;
				detail = detail + "\n- Handle side: "+ this.window_values.handle_side;
				detail = detail + "\n- Active handle colors: "+ this.window_values.active_handle_colors;
				detail = detail + "\n- Notspor: "+ this.window_values.notspor;
				detail = detail + "\n- Childsafe: "+ this.window_values.childsafe;
			} else if (this.type === 'balcony-door') {
				detail = "- Width: "+ this.window_values.width;
				detail = detail + "\n- Height: "+ this.window_values.height;
				detail = detail + "\n- Inside Color: "+ this.window_values.active_colors.inside;
				detail = detail + "\n- Outside Color: "+ this.window_values.active_colors.outside;
				detail = detail + "\n- Active handle colors: "+ this.window_values.active_handle_colors;
				detail = detail + "\n- Isolasjonsgrad: "+ this.window_values.active_isolation;
				detail = detail + "\n- First Glass Type: "+ this.window_values.active_type_of_glass.first;
				detail = detail + "\n- Second Glass Type: "+ this.window_values.active_type_of_glass.second;
				detail = detail + "\n- Notspor: "+ this.window_values.notspor;
				detail = detail + "\n- Door Brake: "+ this.window_values.doorbrake;
				detail = detail + "\n- Lockcylinder: "+ this.window_values.lockcylinder;
				detail = detail + "\n- Threshold: "+ this.window_values.threshold;
				detail = detail + "\n- Horizontal grid: "+ this.window_values.horizontal_grid;
				detail = detail + "\n- Vertical grid: "+ this.window_values.vertical_grid;
				detail = detail + "\n- Brystning: "+ this.window_values.brystning;
				detail = detail + "\n- Slarentning: "+ this.window_values.slarentning;
			} else if (this.type === 'outer-door') {
				detail = "- Width: "+ this.window_values.width;
				detail = detail + "\n- Height: "+ this.window_values.height;
				detail = detail + "\n- Inside Color: "+ this.window_values.active_colors.inside;
				detail = detail + "\n- Outside Color: "+ this.window_values.active_colors.outside;
				detail = detail + "\n- Active handle colors: "+ this.window_values.active_handle_colors;
				detail = detail + "\n- Isolasjonsgrad: "+ this.window_values.active_isolation;
				detail = detail + "\n- First Glass Type: "+ this.window_values.active_type_of_glass.first;
				detail = detail + "\n- Second Glass Type: "+ this.window_values.active_type_of_glass.second;
				detail = detail + "\n- Notspor: "+ this.window_values.notspor;
				detail = detail + "\n- Lockcylinder: "+ this.window_values.lockcylinder;
				detail = detail + "\n- Threshold: "+ this.window_values.threshold;
				detail = detail + "\n- Horizontal grid: "+ this.window_values.horizontal_grid;
				detail = detail + "\n- Vertical grid: "+ this.window_values.vertical_grid;
				detail = detail + "\n- Brystning: "+ this.window_values.brystning;
				detail = detail + "\n- Slarentning: "+ this.window_values.slarentning;
			}

			return detail;
		}
	}
}
</script>
