<template>
	<div class="input-group">
		<button @click.prevent="decrement()" class="input-group-text fw-bold rounded-0">&mdash;</button>
		<input type="text" v-model="quantity" readonly class="form-control text-center">
		<button @click.prevent="increment()" class="input-group-text fw-bold rounded-0">&#xff0b;</button>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Number,
				required: true,
			},
		},

		data() {
			return {
				quantity: 0,
			};
		},

		created() {
			this.quantity = this.value;
		},

		watch: {
			quantity(newValue) {
				this.$emit('input', newValue);
			},
		},

		methods: {
			increment () {
				this.quantity++
			},

			decrement () {
				if(this.quantity > 1) {
					this.quantity--
				}
			}
		}
	};
</script>
